import React, { useState, useEffect } from "react";
import Header from "../componant/HeaderNew";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import Footer from "../Footer";

import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";

import FAQAccordionAi from "../componant/FAQAccordionAidoc";

function Home() {
  const posts = [
    {
      slug: "marketplace-for-lawyers-a-new-era-of-legal-service-delivery-law-blocks-ai",
      title:
        "Marketplace for Lawyers: A New Era of Legal Service Delivery - Law Blocks AI",
      category: "Marketplace for Lawyers",
      date: "July 12, 2024",
      description:
        "Explore how Law Blocks AI is transforming legal service delivery with a new marketplace for lawyers. Discover the benefits of AI-powered legal solutions, streamlined processes, and enhanced client-lawyer interactions.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/Marketplace_slfuz1.jpg",
    },
    {
      slug: "benefits-of-ai-chat-for-legal-professionals-and-clients",
      title: "The Benefits of AI Chat for Legal Professionals and Clients",
      category: "Global Information AI",
      date: "July 12, 2024",
      description:
        "Discover the advantages of AI chat for legal professionals and clients with Law Blocks AI. Learn how AI-powered chat enhances communication, efficiency, and client satisfaction in the legal industry.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ai_chat_sisdav.jpg",
    },
    {
      slug: "how-digital-signature-features-work-on-law-blocks-ai",
      title: "How Digital Signature Features Work on Law Blocks AI",
      category: "Digital Signature",
      date: "July 12, 2024",
      description:
        "Learn how Law Blocks AI enhances digital signature features to provide secure, efficient, and legally binding document signing. Explore the benefits of digital signatures in streamlining legal processes and ensuring document integrity.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ds_thnxrv.jpg",
    },
  ];

  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      // const { top } = fourthSection?.getBoundingClientRect();
      const headerHeight = header.offsetHeight;

      // if (top != null) {
      //   setIsScrolled(top <= headerHeight);
      // }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };
  const navigatelanding = () => {
    window.open(
      "https://market.lawblocks.io/create-legal-template-ai ",
      "_blank"
    );
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header"}>
      <Header />
      <Helmet>
        <title> AI Legal Document Generator | AI Tool Document Creation</title>
        <meta
          property="og:title"
          content=" AI Legal Document Generator | AI Tool Document Creation"
        />
        <meta
          property="og:description"
          content=" Law Blocks AI is an AI tool to create legal documents and contracts. Generate legal documents with AI. Try now."
        />
        {/* <meta 
    property="og:image" 
    content="https://lawblocks.io/assets/ai-legal-document-generator-thumbnail.jpg" 
  /> */}
        <meta
          property="og:url"
          content="https://lawblocks.io/ai-legal-document-generator"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc height_new "
          id="section-4 "
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <div className="hero_seaction_law align-items-start ai_law_hero">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2 ai_work_heading"
                  >
                    How Law Blocks AI Legal Document Creation AI Works
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    Welcome to Law Blocks AI’s Create AI Template Feature
                  </p>
                  <Button
                    onClick={navigatelanding}
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Try for free
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="section_defualt mt-5">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <div
                    className="imag_widtjas"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      alt="AI Legal Document Generator | Law Blocks AI"
                      src={require("../../Image/banner/about_pc.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Overview of Law Blocks AI Legal Document</h2>
                    <p>
                      At Law Blocks AI, we are upgrading the way legal documents
                      are created. Our AI Legal Document Generator leverages
                      artificial intelligence to simplify the document creation
                      process, making it faster, more accurate, and incredibly
                      user-friendly. Whether you are a legal professional or
                      someone managing legal documents for the first time, our
                      platform is designed to meet your needs with efficiency
                      and precision.
                    </p>

                    <Button
                      onClick={navigate}
                      className="laight_button border_radious_button"
                    >
                      Get Started
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>What is a Legal Document/Agreement?</h2>
                    <p>
                      A legal document or agreement is a written instrument that
                      outlines the rights, responsibilities, and obligations of
                      the parties involved. These documents are essential in
                      establishing clear terms and conditions for various
                      transactions and relationships, ensuring that all parties
                      are legally protected. Legal documents can range from
                      simple contracts to complex multi-page agreements, each
                      serving a specific purpose within the legal framework.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                    onClick={navigate}
                  >
                    <img
                      alt="AI Legal Document Generator | Law Blocks AI"
                      src={require("../../Image/banner/AI_doc_2.webp")}
                      className="img-fluid cursor_point"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt bg-gray_section">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center mb-4"
              >
                <div
                  className=" overvide headeing_main"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">
                    Key Benefits of Document Generation AI
                  </h2>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={8}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="300"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",

                    width: "100%",
                    padding: "16px 6px",
                    boxShadow: "none",
                    borderRadius: "20px",
                  }}
                  className="card_dark new_AI_doc_card"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <h3> Efficiency and Speed</h3>
                      <p className="width_60">
                        Create legal templates in a fraction of the time it
                        would take manually. Our Legal Document Generating AI
                        allows you to draft documents quickly, freeing up more
                        time for other critical tasks.
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",

                    padding: "16px 6px",
                    boxShadow: "none",
                    borderRadius: "20px",
                  }}
                  className="card_dark new_AI_doc_card item2"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <h3> Accuracy and Precision</h3>
                      <p>
                        Create legal templates in a fraction of the time it
                        would take manually. Our Legal Document Generating AI
                        allows you to draft documents quickly, freeing up more
                        time for other critical tasks.
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",

                    padding: "16px 6px",
                    boxShadow: "none",
                    borderRadius: "20px",
                  }}
                  className="card_dark new_AI_doc_card item3"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <h3> Customization</h3>
                      <p>
                        Easily customize templates to suit your specific legal
                        needs and preferences. Modify existing templates or
                        create new ones tailored to your requirements with our
                        AI Legal Template Creation feature.
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",

                    padding: "16px 6px",
                    boxShadow: "none",
                    borderRadius: "20px",
                  }}
                  className="card_dark new_AI_doc_card item4"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <h3> User-Friendly Interface</h3>
                      <p>
                        Our intuitive interface is designed to be easy to
                        navigate, even for those with no technical expertise.
                        The guided workflows make document creation
                        straightforward and hassle-free.
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",

                    padding: "16px 6px",
                    boxShadow: "none",
                    borderRadius: "20px",
                  }}
                  className="card_dark new_AI_doc_card item5"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <h3> Compliance</h3>
                      <p>
                        Ensure that all your legal documents are compliant with
                        the latest regulatory requirements. Our AI continuously
                        updates to reflect current laws and standards, so you
                        can be confident in the legality of your documents.
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={8}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",

                    padding: "16px 6px",
                    boxShadow: "none",
                    borderRadius: "20px",
                  }}
                  className="card_dark new_AI_doc_card item6"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <h3> AI-Powered Suggestions</h3>
                      <p className="width_60">
                        Receive intelligent recommendations for clauses and
                        terms based on your document type and legal
                        requirements. Our Legal Document Creation AI provides
                        relevant suggestions to ensure your documents are
                        comprehensive and legally sound.
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",

                    padding: "16px 6px",
                    boxShadow: "none",
                    borderRadius: "20px",
                  }}
                  className="card_dark align-items-center justify-content-center bg_car_pur"
                >
                  <CardContent>
                    <div className="card_inside_content dar_directon">
                      <h3 className="text-center">
                         Transform Your Legal Document Creation with AI
                      </h3>
                      <Button
                        onClick={navigatelanding}
                        className="laight_button"
                        data-aos="zoom-in"
                        data-aos-duration="500"
                      >
                        Try for free
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt background_row_ss">
          <Container maxWidth="xl">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={7}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>How Law Blocks AI Legal Document Creation AI Works</h2>
                    <p>
                      Creating legal documents with Law Blocks AI is simple.
                      Follow these steps to get started
                    </p>
                    <div className="fetuer_section">
                      <div className="half_width">
                        <div>
                          <span>1</span>
                        </div>
                        <div className="content_section_">
                          <h4>Sign Up</h4>
                          <p>
                            Register on the Law Blocks AI Marketplace platform.
                          </p>
                        </div>
                      </div>
                      <div className="half_width">
                        <div>
                          <span>2</span>
                        </div>{" "}
                        <div className="content_section_">
                          <h4>Create AI Document</h4>
                          <p>
                            Register on the Law Blocks AI Marketplace platform.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="fetuer_section">
                      <div className="half_width">
                        <div>
                          <span>3</span>
                        </div>
                        <div className="content_section_">
                          <h4>Enter Location</h4>
                          <p>Enter your Country and State</p>
                        </div>
                      </div>
                      <div className="half_width">
                        <div>
                          <span>4</span>
                        </div>
                        <div className="content_section_">
                          <h4>Specify Document Type</h4>
                          <p>
                            Specify the type of document you need using our AI
                            Legal Agreement Generator.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="fetuer_section w-100">
                      <div className="half_width">
                        <div>
                          <span>5</span>
                        </div>{" "}
                        <div className="content_section_">
                          <h4>Generate</h4>
                          <p>
                            Click “Generate” and let our Legal Agreement
                            Generating AI do the rest
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={5}>
                  <div
                    className="imag_widtjas"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                    onClick={navigate}
                  >
                    <img
                      alt="AI Legal Document Generator | Law Blocks AI"
                      src={require("../../Image/banner/simble_doc.webp")}
                      className="img-fluid cursor_point"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    Here are some common questions and their answers to help you
                    understand how Law Blocks AI can assist you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordionAi />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
